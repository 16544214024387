/* .admin-toolbar {
  border: 1px red solid;
  color: #f49f1c;
  background-color: #030e4f;
} */

.admin-toolbar {
  /* border: 1px red solid; */
  color: whitesmoke;
  /* background-color: #2f2f2f; */
  background-color: #096972;
}

/* 2f2f2f */
/* fd6f27 */
