.main-container {
  /* border: 1px orange solid; */
  /* width: 100%;
  height: 100vh; */
  background-color: #2c3338;
}

.sign-up-footer:hover {
  color: black;
}

#sign-up-btn {
  background-color: #0494a1;
}

.register-page {
  border: 1px rgb(160, 160, 160) solid;
  border-radius: 5px;
  padding: 20px;
}

.sign-up-icons {
  color: rgb(167, 166, 166);
  margin-right: 10px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: whitesmoke;
  font-family: "Roboto Condensed", "sans-serif";
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  color: red;
  font-family: "Roboto Condensed", "sans-serif";
}

.MuiButtonBase-root.MuiMenuItem-gutters {
  font-family: "Roboto Condensed", "sans-serif";
  font-size: 1.1rem;
}
