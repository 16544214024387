.footer {
  color: white;
  text-align: center;
  background-color: #212529;
  padding-top: 25px;
  color: white;
  /* position: absolute; */
  bottom: 0;
  height: 100px;
  width: 100%;
}
