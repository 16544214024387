.leaflet-container {
  height: 90vh;
  width: 100vw;
  margin-top: 64px;
}

/* Routing machine itinerary controle */
.leaflet-routing-alternatives-container {
  display: none;
}

/* .leaflet-routing-container {
  display: none;
} */

/* .leaflet-routing-geocoder input {
  background-color: red;
  border-style: solid;
} */

.user-drawer-icons {
  color: #05e6fa;
}

/* .user-drawer-buttons {
  height: 60px;
} */
.user-drawer-buttons:hover {
  /* color: #00a8b8b4; */
  background: #202727;
  border-bottom: 2px #05e6fa solid;
  /* border-radius: 20px; */
  /* box-shadow: inset 9px 9px 18px #161b1b, inset -9px -9px 18px #2a3333; */
}

/* ***********    Card item css    *********** */
.card-item:hover {
  background: rgba(88, 0, 0, 0.808);
}

/* ***********   form inputs    ************ */
.MuiFormLabel-root.MuiInputLabel-outlined {
  color: whitesmoke;
  font-family: "Roboto Condensed", "sans-serif";
}

.MuiTypography-root.MuiTypography-body1 {
  color: whitesmoke;
  font-family: "Roboto Condensed", "sans-serif";
}

#standard-basic {
  color: whitesmoke;
  font-family: "Roboto Condensed", "sans-serif";
}

/* SweetAlert */
.swal2-title {
  font-family: "Roboto Condensed", "sans-serif";
}
#swal2-html-container.swal2-html-container {
  font-family: "Roboto Condensed", "sans-serif";
}
.swal2-styled.swal2-confirm {
  font-family: "Roboto Condensed", "sans-serif";
}
.swal2-styled.swal2-cancel {
  font-family: "Roboto Condensed", "sans-serif";
}
