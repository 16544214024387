.leaflet-container {
  height: 90vh;
  width: 100vw;
  margin-top: 64px;
  /* position: fixed; */
  /* z-index: 50; */
}

/* Routing machine itinerary controle */
.leaflet-routing-alternatives-container {
  display: none;
}

.leaflet-routing-container {
  display: none;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: black;
}

/* .leaflet-routing-geocoder input {
  background-color: red;
  border-style: solid;
} */

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  .MuiSwitch-track {
  background-color: red;
}
