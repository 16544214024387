.main-container {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px;
}

/* --------------------------------------------- */
/* .custom-navbar {
  display: flex;
  flex-direction: column;
} */

.custom-container {
  border: 1px solid red;
  display: flex;
  /* justify-content: space-between; */
}

.sub-sections {
  border: 1px solid green;
  display: flex;
  /* text-decoration: none; */
}

li {
  /* display: flex; */
  text-decoration: none;
}
/* --------------------------------------------- */

.header-a {
  padding-top: 20px;
  padding-bottom: 20px;
}

.alink {
  /* background-color: red; */
  font-size: 14px;
  font-weight: 600;
}

.alink {
  color: black;
}

.nav-link {
  color: black !important;
}

.alink ul {
  float: left;
}

.nav-container .start {
  font-weight: 600;
  color: black;
}

.navbar-nav > li {
  padding-left: 35px;
  padding-right: 35px;
}

.start {
  border-radius: 9px;
  border-width: 0.1em;
  font-size: 15px;
  padding: 10px 20px 10px 20px;
  margin-left: 30px;
}

.start:hover {
  color: white;
}

.brand {
  font-weight: bolder;
}
