.yeah {
  /* border: 1px orange solid; */
  width: 100%;
  height: 100vh;
  background-color: #2c3338;
}

.login {
  border: 1px rgba(0, 128, 0, 0) solid;
  /* width: 400px; */
  background-color: #2c3338;
  /* background-color: #2c2a2a; */
}

.login-page {
  /* background: #c0bfc2; */
  border: 1px rgb(160, 160, 160) solid;
  border-radius: 5px;
  padding: 20px;
}

#sign-in-btn {
  background-color: #0494a1;
}

.sign-in-icons {
  color: rgb(167, 166, 166);
  /* border: 1px red solid; */
  margin-right: 10px;
}

/* #ea4c88 : rose */
/* #05E6FA, #0494a1 : neon blue */

.sign-in-footer:hover {
  color: black;
}
