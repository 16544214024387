.fold {
  width: 100%;
  background-image: url("../../images/paul-unsplash.jpg");
  background-color: #f4f5ff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
}

.title {
  padding-top: 150px;
  /* border: 1px red solid; */
}
